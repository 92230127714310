<template>
	<span>
		<span @click="onShowModal">
			<slot>
				发放礼包
			</slot>
		</span>
		<a-modal v-model:visible="visible" title="选择券" width="810px" @cancel="onCancel" :maskClosable="false"
			@ok="onSelectOk">
			<a-form ref="form" name="form" :model="formState" @finish="onSearch">
				<a-row>

					<a-form-item class="ui-form__item" label="用户名称" name="username">
						<a-input placeholder="请输入用户名称" v-model:value="formState.username" />
					</a-form-item>

					<a-form-item class="ui-form__item" label="用户电话" name="phone">
						<a-input placeholder="请输入用户电话" v-model:value="formState.phone" />
					</a-form-item>

				</a-row>

				<a-row>
					<a-col :span="18" style="padding-top: 10px;">
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button type="primary" html-type="submit">搜索</a-button>
						<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :columns="columns" :dataSource="list" rowKey="id" :pagination="pagination"
					:rowSelection="{ type: rowSelectionType, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
					:scroll="{ x: 500 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'action'">
							<a-button @click="onSelectOne(record)">发放</a-button>
						</template>
					</template>
				</a-table>
			</div>
		</a-modal>
	</span>
</template>

<script>
	import {
		grantAppCouponPacket
	} from "@/service/modules/coupon";
	import {
		getMemberList
	} from "@/service/modules/member";
	export default {
		props: {
			value: {
				type: Array,
				default: () => {
					return []
				}
			},
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				visible: false,
				formState: {

				},
				searchData: {},
				rowSelectionType: 'checkbox',
				selectedRowKeys: [],
				list: [],
				columns: [{
					title: "用户名称",
					dataIndex: "nickname",
				}, {
					title: "用户电话",
					dataIndex: "phone",
				}, {
					title: '操作',
					key: 'action',
					width: 50
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
					}
				},
			}
		},
		created() {
			this.selectedRowKeys = this.value;
		},
		methods: {
			onShowModal() {
				this.visible = true;
				this.selectedRowKeys = [];
				this.$nextTick(() => {
					this.reset();
				})
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			onCancel() {
				this.visible = false;
			},
			onSelectOne(item) {
				this.selectedRowKeys = [];
				this.selectedRowKeys.push(item.id);
				this.visible = false;
				this.$emit('update:value', JSON.parse(JSON.stringify(this.selectedRowKeys)));
				// if(this.selectedRowKeys.indexOf(item.id) === -1) {
				//   this.selectedRowKeys.push(item.id);
				//   this.visible = false;
				//   this.$emit('update:value', JSON.parse(JSON.stringify(this.selectedRowKeys)));
				// };
				this.grantAppCouponPacket();
			},
			onSelectOk() {
				this.grantAppCouponPacket();
				this.visible = false;
			},
			onSelectChange(record, selected) {
				if (this.rowSelectionType === 'checkbox') {
					if (selected) {
						this.selectedRowData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
						this.selectedRowData = this.selectedRowData.filter(item => {
							return item.id !== record.id;
						});
					}
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedRowData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedRowData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getMemberList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			// 发放礼包
			async grantAppCouponPacket() {
				this.$confirm({
					title: '提示',
					content: '确认要发放礼包吗？',
					onOk: async () => {
						let userIds = this.selectedRowKeys.join(',');
						this.loading = true;
						try {
							let ret = await grantAppCouponPacket({
								userIds: userIds,
								packetId: this.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>