import { send } from "../index";

export function getMemberList(data, opts = {}) {
	//  用户列表
    return send({
        url: "/admin/baseUser/searchList.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getMemberDetail(data, opts = {}) {
	//  用户详情
    return send({
        url: "/admin/baseUser/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledUser(data, opts = {}) {
	//  禁用用户
    return send({
        url: "/admin/baseUser/pullOffShelves.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function enabledUser(data, opts = {}) {
	//  解禁用户
    return send({
        url: "/admin/baseUser/pullOnShelves.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getAllMemberRightList(data, opts = {}) {
    // 会员权益全部列表
    return send({
        url: "/admin/memberRight/getAll.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getMemberRightList(data, opts = {}) {
    // 会员权益列表
    return send({
        url: "/admin/memberRight/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getMemberRightDetail(data, opts = {}) {
    // 会员权益详情
    return send({
        url: "/admin/memberRight/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function saveMemberRight(data, opts = {}) {
    // 会员权益新增
    return send({
        url: "/admin/memberRight/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateMemberRight(data, opts = {}) {
    // 会员权益编辑
    return send({
        url: "/admin/memberRight/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledMemberRight(data, opts = {}) {
    // 会员权益启用禁用
    return send({
        url: "/admin/memberRight/updateIsDisabled.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getMemberLevelList(data, opts = {}) {
    // 会员等级列表
    return send({
        url: "/admin/memberLevel/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getMemberLevelDetail(data, opts = {}) {
    // 会员等级详情
    return send({
        url: "/admin/memberLevel/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function saveMemberLevel(data, opts = {}) {
    // 会员等级新增
    return send({
        url: "/admin/memberLevel/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateMemberLevel(data, opts = {}) {
    // 会员等级编辑
    return send({
        url: "/admin/memberLevel/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getMemberLevelAllList(data, opts = {}) {
    // 获取全部会员等级
    return send({
        url: "/admin/memberLevel/getAll.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getArchiveCode(data, opts = {}) {
    // 特色会员批量生成
    return send({
        url: "/admin/memberLevel/getArchiveCode.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getGeneratorRecordList(data, opts = {}) {
    // 特色会员批量生成记录
    return send({
        url: "/admin/specialMemberGeneratorRecord/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getGeneratorRecordDetail(data, opts = {}) {
    // 特色会员批量生成记录详情
    return send({
        url: "/admin/specialMemberGeneratorRecord/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledGeneratorRecord(data, opts = {}) {
    // 特色会员批量生成记录启用/禁用
    return send({
        url: "/admin/specialMemberGeneratorRecord/updateStatus.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getGeneratorList(data, opts = {}) {
    // 特色会员批量生成会员列表
    return send({
        url: "/admin/specialMemberArchiveCode/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledGeneratorList(data, opts = {}) {
    // 特色会员批量生成会员启用/禁用
    return send({
        url: "/admin/specialMemberArchiveCode/updateStatus.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getMemberTaskList(data, opts = {}) {
    // 会员任务列表
    return send({
        url: "/admin/memberTask/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateMemberTask(data, opts = {}) {
    // 会员任务更新
    return send({
        url: "/admin/memberTask/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function grantMember(data, opts = {}) {
    // 特色会员发放
    return send({
        url: "/admin/grantSpecialMember/grant.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function switchSpecialMember(data, opts = {}) {
    // 特色会员启用/禁用
    return send({
        url: "/admin/userSpecialMemberRecord/switch.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getUserPointRecord(data, opts = {}) {
    // 用户记录变动记录
    return send({
        url: "/admin/userPointRecord/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getUserAssetsRecord(data, opts = {}) {
    // 资产变动记录
    return send({
        url: "/admin/userAssetsRecord/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function changeUserPoint(data, opts = {}) {
    // 变动用户积分
    return send({
        url: "/admin/userPointRecord/changePoint.do",
        method: "POST",
        data,
        ...opts,
    });
}