<template>
	<div>
		<Header :title="isEdit ? '修改礼包' : '新增礼包'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 30px;" ref="form" :model="modelRef" name="form" :labelCol="{span: 6, xxl: 5}"
				:wrapperCol="{span: 14, xxl: 13 }">

				<a-form-item name="title" label="礼包标题" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-input v-model:value="modelRef.title" placeholder="请输入礼包标题"></a-input>
				</a-form-item>
				
				<a-form-item label="图片" name="image" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-upload
					    v-model:file-list="fileList"
					    list-type="picture-card"
					    action="/admin/ajaxUpload.do"
					    @change="handleChange"
					>
					  <div v-if="fileList.length < 1">
					    <Icon icon="PlusOutlined"></Icon>
					    <div style="margin-top: 8px">Upload</div>
					  </div>
					</a-upload>
				</a-form-item>

				<a-form-item label="礼包券">
					<a-form-item-rest>
						<selectCoupon v-model:value="couponList" :selectItems="list" @change="onCouponChange"></selectCoupon>
					</a-form-item-rest>
					<a-form-item-rest>
						<div style="margin-top: 10px; width: 700px;" v-if="list.length">
							<a-table :pagination="false" :columns="columns" :dataSource="list" rowKey="id"
								:scroll="{ x: 500 }">
								<template #bodyCell="{ column, record, index }">
									<template v-if="column.key === 'type'">
										<div v-if="record.type === 1">代金券</div>
										<div v-if="record.type === 2">兑换券</div>
									</template>
									<template v-if="column.key === 'useLimit'">
										<div v-if="record.useLimit === 1">影片</div>
										<div v-if="record.useLimit === 2">演出</div>
										<div v-if="record.useLimit === 3">卖品</div>
										<div v-if="record.useLimit === 4">商城</div>
									</template>
									<template v-if="column.key === 'num'">
										<a-input-number v-model:value="record.num" :min="1" :precision="0" placeholder="请输入"></a-input-number>
									</template>
									<template v-if="column.key === 'sort'">
										<a-input-number v-model:value="record.sort" :min="0" :precision="0" placeholder="请输入"></a-input-number>
									</template>
									<template v-if="column.key === 'action'">
										<a-button type="link" @click="onDelete(record, index)">删除</a-button>
									</template>
								</template>
							</a-table>
						</div>
					</a-form-item-rest>
				</a-form-item>

				<a-form-item label="每日限购数" name="timeBuyNum">
					<a-input-number :min="0" :precision="0" v-model:value="modelRef.timeBuyNum" placeholder="请输入每日限购数"
						style="width: 200px; border-bottom-left-radius: 10px; border-top-left-radius: 10px"></a-input-number>
					<span class="ui-min">件</span>
				</a-form-item>

				<a-form-item label="限购数" name="limitNum" extra="填0则不限购">
					<a-input-number :min="0" :precision="0" v-model:value="modelRef.limitNum" placeholder="请输入限购数"
						style="width: 200px; border-bottom-left-radius: 10px; border-top-left-radius: 10px"></a-input-number>
					<span class="ui-min">件</span>
				</a-form-item>

				<a-form-item label="库存" name="stockNum" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-input-number :min="0" :precision="0" v-model:value="modelRef.stockNum" placeholder="请输入库存"
						style="width: 200px; border-bottom-left-radius: 10px; border-top-left-radius: 10px"></a-input-number>
					<span class="ui-min">件</span>
				</a-form-item>

				<a-form-item label="基础已售" name="baseSaleNum">
					<a-input-number :min="0" :precision="0" v-model:value="modelRef.baseSaleNum" placeholder="请输入基础已售"
						style="width: 200px; border-bottom-left-radius: 10px; border-top-left-radius: 10px"></a-input-number>
					<span class="ui-min">件</span>
				</a-form-item>

				<a-form-item label="售价" name="salePrice">
					<a-input-number :min="0" :precision="0" v-model:value="modelRef.salePrice" placeholder="请输入售价"
						style="width: 200px; border-bottom-left-radius: 10px; border-top-left-radius: 10px"></a-input-number>
					<span class="ui-min">件</span>
				</a-form-item>

				<a-form-item name="sort" label="优先级" extra="优先级越高越靠前，不填则为0">
					<a-input-number :min="0" :precision="0" style="width: 100%;" v-model:value="modelRef.sort"
						placeholder="请输入优先级"></a-input-number>
				</a-form-item>

				<a-form-item label="是否启用" name="isDisabled">
					<a-switch v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
				</a-form-item>

				<a-form-item label="购买说明" name="buyDesc">
					<a-textarea v-model:value="modelRef.buyDesc" placeholder="请输入购买说明"></a-textarea>
				</a-form-item>

				<a-row>
					<a-col :span="24" style="margin-top: 20px;text-align: center;">
						<a-button type="primary" html-type="submit" @click="onSubmit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</a-col>
				</a-row>

			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import {Icon} from '@/components/icon/icon.js';
	import selectCoupon from "@/components/select/selectCoupon";
	import Header from '@/components/header/header.vue';
	import {
		addAppCouponPacket,
		updateAppCouponPacket,
		getAppCouponPacketDetail
	} from "@/service/modules/coupon";

	export default {
		components: {
			Icon,
			Header,
			selectCoupon,
		},
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				loading: false,
				fileList: [],
				modelRef: {
					couponList: [],
				},
				couponList: [],
				selectedRowKeys: [],
				columns: [{
					title: '券标题',
					dataIndex: 'title',
				}, {
					title: '券类型',
					key: 'type',
				}, {
					title: '使用场景',
					key: 'useLimit',
				}, {
					title: '券发放数量',
					key: 'num'
				}, {
					title: '优先级',
					key: 'sort',
				}, {
					title: '操作',
					key: 'action',
				}],
				list: [],
			}
		},
		created() {
			if (this.isEdit) {
				this.getData();
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getAppCouponPacketDetail({
						id: this.id
					})
					this.loading = false;
					if (ret.code === 200) {
						ret.data.isDisabled = ret.data.isDisabled ? false : true;
						if (ret.data.couponList) {
							ret.data.couponList.forEach(item => {
								item.recorId = item.id;  // 记录id
								item.id = item.couponId; // 券id
								this.couponList.push(item.couponId);
							})
						}
						this.list = ret.data.couponList;
						delete ret.data.couponList;
						if (ret.data.image) {
							this.fileList = [{
								uid: 1,
								url: ret.data.image,
								name: ret.data.image
							}]
						}
						this.modelRef = ret.data;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onDelete(item, index) {
				let i = this.couponList.indexOf(item.id);
				this.couponList.splice(i, 1);
				this.list.splice(index, 1);
			},
			async onSubmit() {
				this.$refs.form.validateFields().then(async () => {
					this.modelRef.couponList = [];
					this.list.forEach(item => {
						this.modelRef.couponList.push({
							couponId: item.id,
							id: item.recordId ? item.recordId : undefined,
							sort: item.sort,
							num: item.num
						})
					})
					this.modelRef.isDisabled = this.modelRef.isDisabled ? 0 : 1;
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					this.loading = true;
					try {
						let ret;
						if (this.isEdit) {
							postData.id = this.id;
							ret = await updateAppCouponPacket(postData);
						} else {
							ret = await addAppCouponPacket(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.onBack(true);
						}
					} catch (e) {
						this.loading = false;
					}
				})
			},
			onCouponChange(data) {
				this.list = data.map((item) => {
					if (!item.num) {
						item.num = 1;
					}
					return item;
				})
			},
			handleChange(info) {
			  if (info.file.status === 'done') {
			    this.fileList = info.fileList;
			    const $fileList = [...this.fileList];
			    this.fileList = $fileList.map(file => {
			      if (file.response) {
					this.modelRef.image = file.response.data.imgUrl;
			        file.url = file.response.data.imgUrl;
			      }
			      return file;
			    });
			  }
			},
		}
	}
</script>

<style scoped>
	.ui-min {
		display: inline-block;
		width: 40px;
		height: 31px;
		text-align: center;
		line-height: 31px;
		background-color: #d7d7d7;
		border-bottom-right-radius: 10px;
		border-top-right-radius: 10px;
	}
</style>